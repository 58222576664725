<!--
 * @version: v 1.0.0
 * @Github: https://github.com/GitHubGanKai
 * @Author: GitHubGanKai
 * @Date: 2020-12-27 20:41:48
 * @LastEditors: gankai
 * @LastEditTime: 2021-01-02 20:29:34
 * @FilePath: /refactor-with-vue3/src/views/home/index.vue
-->
<template>
  <div  class="main">
<!--    这里是登录-->

<!--   <p> {{data.url}}</p>-->

<!--    <div style="display: flex;width: 100%;flex-direction: column;align-items: center;justify-content: center">-->
<!--      <button @click="toGetAuth">获取授权</button>-->

<!--      <button @click="toShareInfo">分享</button>-->
<!--    </div>-->

  </div>
</template>

<script>
import {onMounted, toRefs, getCurrentInstance, reactive,} from "vue";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";


import {
  changeToDate,
  clearToast,
  formatDate, getStore, loginToPlatform, removeStore,
  setStore,
  showFailToast,
  showLoading,
  showSuccessToast
} from "../../utils/util";
import router from "../../router";


export default {
  name: "login",
  components: {

  },
  setup(props, context) {
    const { proxy } = getCurrentInstance();
    const $store = useStore();
    const $router = useRouter();
    const $route = useRoute();

    const data = reactive({
      url:''
    })

    onMounted(() => {
      // getHwToken()

    })




    const toGetAuth = () => {

      // //判断有没有token
      // if(getStore('token')){
      //   //判断是否登录有效
      //   showLoading();
      //   proxy.$http.get("/wenwu-user/my/index").then(res => {
      //     clearToast()
      //     if(res.data.code == 200){
      //       $router.replace('/home')
      //     }else{
      //       removeStore('token')
      //       let reUrl = encodeURI(window.location.origin + '/index');
      //       window.location.replace("https://mycs.csbtv.com/api/oauth/authorize?client_id=WhohHXLP98&response_type=code&redirect_uri="+reUrl+"&scope=read");
      //     }
      //
      //
      //   }).catch((err)=>{
      //
      //     removeStore('token')
      //     let reUrl = encodeURI(window.location.origin + '/index');
      //     window.location.replace("https://mycs.csbtv.com/api/oauth/authorize?client_id=WhohHXLP98&response_type=code&redirect_uri="+reUrl+"&scope=read");
      //
      //   })
      //
      //
      // }else{
        if(!$route.query.code){
          let reUrl = encodeURI(window.location.origin + '/index');
          window.location.replace("https://mycs.csbtv.com/api/oauth/authorize?client_id="+ process.env.VUE_APP_CLIENT_ID +"&response_type=code&redirect_uri="+reUrl+"&scope=read");
        }else{//这里是去登录

          showLoading();
          let params = {code:$route.query.code};
          proxy.$http.post("/wenwu-user/user/login",params).then(res => {
            clearToast()
            //存储token
            setStore("token", res.data.data.satoken)

            $router.replace('/home')
          }).catch((err)=>{
            showFailToast(err.message,1000);
          })
        }
      }
      // proxy.$http.post("/wenwu-user/user/login",{code:'Zikmrm'}).then(res => {
      //   clearToast()
      //   //存储token
      //   setStore("token", res.data.data.satoken)
      //   $router.replace('/home')
      // })

      // setStore("token", "0fa346ea-03fe-4c38-b8f9-37b4abf39082")
      // $router.replace('/home')



    // }



    toGetAuth()




    return {
      data,
      toGetAuth,
    };
  }
};
</script>

<style scoped lang="scss" src="./index.scss">

</style>

